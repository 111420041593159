import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modalVisibility: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    modalToggled(state, action) {
      return {
        ...state,
        modalVisibility: action.payload,
      }
    },
  },
})

export const { modalToggled } = modalSlice.actions

export default modalSlice.reducer
