import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Centered.module.scss'

const Centered = ({ className, children }) => (
  <div
    className={cx(
      'container',
      'd-flex',
      'justify-content-center',
      'align-items-center',
      styles.container,
      className,
    )}
  >
    {children}
  </div>
)

Centered.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Centered
