import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { submitLogout } from 'redux/actions'

const Header = () => {
  const { is_logged_in } = useSelector((s) => s.auth)
  const dispatch = useDispatch()
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-lighter p-3">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          Developer Portal
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            {/* TODO: Routing */}
            <NavLink
              to="/documentation"
              className="nav-link"
              activeClassName="active"
            >
              Docs
            </NavLink>
            {!is_logged_in && (
              <>
                <NavLink
                  to="/login"
                  className="nav-link"
                  activeClassName="active"
                >
                  Login
                </NavLink>
                <NavLink
                  to="/register"
                  className="nav-link text-primary"
                  activeClassName="active fw-bold"
                >
                  Sign Up
                </NavLink>
              </>
            )}
            {is_logged_in && (
              <div
                role="link"
                className="nav-link"
                tabIndex={0}
                onClick={() => dispatch(submitLogout())}
                onKeyDown={() => dispatch(submitLogout())}
              >
                Log out
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
