import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './Heading.module.scss'

const Heading = ({ children, className }) => (
  <h1 className={cx('h1', 'text-primary', 'fw-bold', className)}>{children}</h1>
)
const Subheading = ({ children, className }) => (
  <h2 className={cx('h2', 'fw-bold', styles.subheading, className)}>
    {children}
  </h2>
)

Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  className: PropTypes.string,
}

Subheading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  className: PropTypes.string,
}

export { Heading, Subheading }
