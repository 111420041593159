import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import Centered from 'components/Centered'
import CardOrNot from 'components/CardOrNot'
import { Container } from 'components/Container'
import { Button } from '@shimple-solutions/mm-ui-kit'
import ImageOne from 'components/ImageOne'
import { Text } from 'components/Text'

// Assets
import CatShake from 'assets/images/cat-shake.svg'
import { Subheading } from 'components/Heading'

const ResetPasswordSuccess = ({ location }) => {
  if (location.state?.email) {
    return <Redirect to="/" />
  }
  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex align-items-center justify-content-center">
                <ImageOne src={CatShake} alt="shaking hands" />
              </div>
              <div className="col-sm p-3 text-center text-md-start">
                <Subheading>Success</Subheading>
                <Text>Your password has been changed!</Text>
                <Link to="/login">
                  <Button type="button" btnStyle="primary" isLoading={false}>
                    Continue
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

ResetPasswordSuccess.propTypes = {
  location: PropTypes.object,
}

export default ResetPasswordSuccess
