const Footer = () => (
  <footer className="footer mt-auto py-5 bg-primary">
    <div className="container d-flex text-white justify-content-around">
      <div className="d-flex flex-column">
        <p className="fw-bold">Developers</p>
        <p className="mb-0">API Documentation</p>
        <p className="mb-0">Getting Started</p>
        <p className="mb-0">Pricing</p>
      </div>
      <div className="d-flex flex-column">
        <p className="fw-bold">General</p>
        <p className="mb-0">Terms Of Use</p>
        <p className="mb-0">Back to Manamakan</p>
      </div>
    </div>
  </footer>
)

export default Footer
