import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { NavLink } from 'react-router-dom'

// Components
import Centered from 'components/Centered'
import { Input } from 'components/Input'
import { Button } from '@shimple-solutions/mm-ui-kit'
import { Container } from 'components/Container'
import { Heading } from 'components/Heading'
import { Text } from 'components/Text'
import ImageOne from 'components/ImageOne'

// Assets
import CatLogo from 'assets/images/ridingCat.svg'

// eslint-disable-next-line no-unused-vars
const RegistrationForm = ({ history, onSubmit }) => (
  <Formik
    initialValues={{
      email: '',
    }}
    validationSchema={Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    })}
    onSubmit={({ email }) => {
      // onSubmit(email)
      history.push('/register', { email })
    }}
  >
    {({ isSubmitting }) => (
      <Form className="form">
        <Input
          id="email"
          label="email"
          name="email"
          size="lg"
          placeholder="email"
        />
        <Button
          type="submit"
          className="fw-bold align-self-stretch"
          btnStyle="primary"
          isLoading={isSubmitting}
        >
          Register
        </Button>
      </Form>
    )}
  </Formik>
)

const Home = ({ history }) => (
  <Container>
    <Centered>
      <div className="container" style={{ maxWidth: 800 }}>
        <div className="row">
          <div className="col-sm d-flex align-items-center justify-content-center">
            <ImageOne src={CatLogo} />
            {/* <CatLogo style={{ width: 300 }} /> */}
          </div>
          <div className="col-sm text-center text-md-start">
            <Heading>Start creating</Heading>
            <Text>
              applications with the latest Brunei-based property and business
              data.
            </Text>
            <RegistrationForm history={history} />
            <Text className="my-3">Or</Text>
            <NavLink className="nav-link px-0" to="/login">
              login instead
            </NavLink>
          </div>
        </div>
      </div>
    </Centered>
  </Container>
)

Home.propTypes = {
  history: PropTypes.object,
}

RegistrationForm.propTypes = {
  history: PropTypes.object,
  onSubmit: PropTypes.func,
}

export { Home, RegistrationForm }
