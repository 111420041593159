import { createActions } from 'redux-actions'

import api from 'common/api'
import { LOGIN_SUCCESS, SIGN_OUT } from './constants'

const { loginSuccess, signOut } = createActions(
  {
    [LOGIN_SUCCESS]: ({ data }) => {
      // TODO: include refresh token
      // localStorage.setItem("refresh_token", data.session.refresh_token);
      localStorage.setItem('access_token', data.AccessToken)
      return {
        access_token: data.AccessToken,
        // TODO: include user data
        user: data.user || null,
      }
    },
  },
  SIGN_OUT,
)

const checkHasUser = () => (dispatch) => {
  // TODO: refactor this to use refresh_token
  const access_token = localStorage.getItem('access_token')
  if (access_token) {
    return api('/profile', {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => {
        dispatch(
          loginSuccess({ data: { AccessToken: access_token, user: data } }),
        )
        return Promise.resolve(true)
      })
      .catch((error) => {
        console.log('Invalid!', error.message)
        localStorage.removeItem('access_token')
        return Promise.resolve(false)
      })
  }
  return Promise.resolve(false)
}

const submitLogout = () => (dispatch) => {
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('access_token')
  dispatch(signOut())
}

export { loginSuccess, checkHasUser, submitLogout }
