import cx from 'classnames'
import PropTypes from 'prop-types'

// Components
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'

export const Container = ({ className, children }) => (
  <div className={cx('bg-lighter', className)}>
    <Header />
    {children}
    <Footer />
  </div>
)

export const ContainerWithSidebar = ({ className, children }) => (
  <div className={cx('bg-lighter', className)}>
    <Sidebar>{children}</Sidebar>
    <Footer />
  </div>
)

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

ContainerWithSidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
