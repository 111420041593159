import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './ImageOne.module.scss'

const ImageOne = ({
  src,
  alt = 'An image description',
  className,
  ...props
}) => (
  <img
    src={src}
    alt={alt}
    className={cx('img-fluid', 'w-100', styles.image, className)}
    {...props}
  />
)

ImageOne.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}
export default ImageOne
