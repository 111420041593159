import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import api from 'common/api'

// Reducers
import modalReducer from 'components/modal/modalSlice'
import authReducer from './reducer'

const reducers = combineReducers({
  auth: authReducer,
  modal: modalReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))

store.subscribe(() => {
  const storage = store.getState()
  if (storage.auth.access_token) {
    api.defaults.headers = {
      Authorization: `Bearer ${storage.auth.access_token.access_token}`,
    }
  } else {
    api.defaults.headers = {}
  }
})

export default store
