import React from 'react'
import PropTypes from 'prop-types'
import { useTable, usePagination } from 'react-table'
import cx from 'classnames'

// Components
import { Button } from '@shimple-solutions/mm-ui-kit'

// TODO : Pagination and table styling

/**
 * This table renders the APIs retrieved from /get-api-keys endpoint. It receives
 * an array of API Objects, and modifies (shapes) each object and pushing it to
 * a new array which gets accepted as the table data.
 * @param {Array} apiArray API Array containing unmodified API objects
 */
const APIKeysTable = ({ apiArray }) => {
  const data = React.useMemo(() => {
    return apiArray.map((apiObj, index) => ({
      id: index + 1,
      app: apiObj.app_name,
      api: apiObj.apikey,
      date: apiObj.created_at,
    }))
  }, [apiArray]) // this function triggers when apiArray changes otherwise it won't
  const columns = React.useMemo(
    () => [
      {
        Header: 'Ref ID',
        accessor: 'id',
      },
      {
        Header: 'App Name',
        accessor: 'app',
      },
      {
        Header: 'API Key',
        accessor: 'api',
      },
      {
        Header: 'Created On',
        accessor: 'date',
      },
    ],
    [],
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page, // Instead of using 'rows', we'll use page,
    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    usePagination,
  )

  return (
    <>
      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={cx(
                    'table-primary p-3',
                    column.id === 'id' ? 'rounded-start' : '',
                    column.id === 'date' ? 'rounded-end' : '',
                  )}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="table-secondary p-3">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="container-fluid d-flex justify-content-between">
        <Button btnStyle="primary" onClick={() => previousPage()}>
          Previous Page
        </Button>
        <Button btnStyle="primary" onClick={() => nextPage()}>
          Next Page
        </Button>
      </div>
    </>
  )
}

APIKeysTable.propTypes = {
  apiArray: PropTypes.array,
}

export default APIKeysTable
