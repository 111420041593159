// import PropTypes from 'prop-types'

// Components
// import { Text } from 'components/Text'
// import Centered from 'components/Centered'
import { ContainerWithSidebar } from 'components/Container'

// Sections
import APIKeysSection from './APIKeysSection'

const PrivateHome = () => {
  return (
    <ContainerWithSidebar>
      <APIKeysSection />
    </ContainerWithSidebar>
  )
}

// PrivateHome.propTypes = {
//   location: PropTypes.object,
//   history: PropTypes.object,
// }

export default PrivateHome
