import cx from 'classnames'
import PropTypes from 'prop-types'

export const Text = ({ children, ...props }) => <p {...props}>{children}</p>

export const ErrorText = ({ children, className }) => (
  <Text className={cx('text-danger', className)}>{children}</Text>
)

export const LeadingText = ({ children, className }) => (
  <Text className={cx('lead', className)}>{children}</Text>
)

export const WarningText = ({ children, className }) => (
  <Text className={cx('text-warning', className)}>{children}</Text>
)

export const CodeText = ({ children, className }) => (
  <Text className={cx('font-monospace', className)}>{children}</Text>
)

export const GreenText = ({ children, className }) => (
  <Text className={cx('text-success', className)}>{children}</Text>
)

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
}

ErrorText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
}

LeadingText.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
}

WarningText.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
}

CodeText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
}

GreenText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string]),
  className: PropTypes.string,
}
