import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'redux/store'
import 'bootstrap/dist/js/bootstrap'

import './index.scss'
import '@shimple-solutions/mm-ui-kit/dist/index.cjs.css'

import App from './App'
// eslint-disable-next-line import/no-named-as-default-member
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
