import { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Components
import Centered from 'components/Centered'
import CardOrNot from 'components/CardOrNot'
import { Container } from 'components/Container'
import { Input } from 'components/Input'
import { Button } from '@shimple-solutions/mm-ui-kit'
import { Text } from 'components/Text'
import ImageOne from 'components/ImageOne'
import { Subheading } from 'components/Heading'

// Assets
import CatLogo from 'assets/images/catWithFood.svg'
import api from 'common/api'
import ErrorHandling from 'components/ErrorHandling'

const ForgotPasswordRequest = ({ history }) => {
  const [hasError, setError] = useState(null)
  const forgotPassword = ({ email }) => {
    // eslint-disable-next-line no-console
    setError(null)
    return api
      .post('/forgot-password', { email })
      .then((resp) => {
        if (resp.data.error === false) {
          console.log('Response', resp)
          history.push('/reset-password', { email })
        }
        if (resp.data.error === true) {
          setError(resp.data.message)
        }
      })
      .catch((error) => {
        console.log('Error response', error.message)
        setError(error)
      })
  }

  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex align-items-center justify-content-center">
                <ImageOne src={CatLogo} alt="cat logo" />
              </div>
              <div className="col-sm p-3">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email('Invalid email address')
                      .required('Email is required'),
                  })}
                  onSubmit={(formVal) => forgotPassword(formVal)}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Subheading className="text-center text-md-start">
                        Forgot Password
                      </Subheading>
                      <Text>
                        Please provide your email to begin your password reset.
                        An email will be sent to your registered email address.
                      </Text>
                      <Input
                        label="Email"
                        id="email"
                        name="email"
                        type="text"
                        size="lg"
                        placeholder="email"
                      />
                      {hasError && <ErrorHandling error={hasError} />}
                      <Button
                        type="submit"
                        btnStyle="primary"
                        className="w-100"
                        isLoading={isSubmitting}
                      >
                        Request
                      </Button>
                      <NavLink className="nav-link text-center" to="/login">
                        <p>back to login</p>
                      </NavLink>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

ForgotPasswordRequest.propTypes = {
  history: PropTypes.object,
}

export default ForgotPasswordRequest
