import useSWR from 'swr'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

// Components
import { Container } from 'components/Container'
import BaseLayout from 'components/BaseLayout'
import ErrorHandling from 'components/ErrorHandling'
import Loading from 'components/Loading'
import APIRequestInfo from 'components/APIRequestInfo'

import api from 'common/api'
import DocModel from 'common/models/DocModel'

const Layout = ({ children }) => {
  return (
    <Container>
      <BaseLayout>{children}</BaseLayout>
    </Container>
  )
}

const Documentation = () => {
  // const [apiArray, setArray] = useState([])

  const { data, error, isValidating } = useSWR('doc', (url) => api.get(url))

  const docData = useMemo(() => {
    if (!data || error) return []
    const doc = new DocModel(data.data)
    return doc.collection
  })

  if (error) {
    return (
      <Layout>
        <ErrorHandling error={error} />
      </Layout>
    )
  }

  if (!data || isValidating) {
    return (
      <Layout>
        <Loading isComponent />
      </Layout>
    )
  }
  return (
    <Layout>
      {docData.map((item) => (
        <APIRequestInfo apiObj={item} key={item.id} />
      ))}
    </Layout>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Documentation
