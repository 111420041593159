import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'

// Components
import Centered from 'components/Centered'
import CardOrNot from 'components/CardOrNot'
import { Container } from 'components/Container'
import { Button } from '@shimple-solutions/mm-ui-kit'
import { Input, PasswordInput } from 'components/Input'
import { Subheading } from 'components/Heading'
import ErrorHandling from 'components/ErrorHandling'
import { Text } from 'components/Text'
import ImageOne from 'components/ImageOne'

// Assets
import api from 'common/api'
import CatSearch from 'assets/images/cat-search.svg'

const ResetPassword = ({ history, location }) => {
  const [hasError, setError] = useState(null)

  const confirmForgotPassword = (formVal) => {
    // eslint-disable-next-line no-console
    return api
      .post('/confirm-forgot-password', formVal)
      .then((resp) => {
        if (resp.data.error === false) {
          console.log('Response', resp)
          history.push('/reset-password-success', { email: formVal.email })
        }
        if (resp.data.error === true) {
          setError('An error has occured!')
        }
      })
      .catch((error) => {
        setError(error)
      })
  }

  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex align-items-center justify-content-center p-3">
                <ImageOne src={CatSearch} alt="cartoon cat search" />
              </div>
              <div className="col-sm p-3">
                <Subheading className="text-center text-md-start">
                  Reset Password
                </Subheading>
                <Text>
                  Please provide your email to begin your password reset. An
                  email with a verification code has been sent to your
                  registered email address.
                </Text>

                <Formik
                  initialValues={{
                    email: location.state?.email || '',
                    confirmation_code: '',
                    password: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email('Invalid email address')
                      .required('Email is required'),
                    confirmation_code: Yup.string().required(
                      'Check your email for your code',
                    ),
                    password: Yup.string()
                      .required('Password is required')
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
                        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
                      ),
                  })}
                  onSubmit={(e) => confirmForgotPassword(e)}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        size="lg"
                        placeholder="email"
                      />
                      <Input
                        id="confirmation_code"
                        name="confirmation_code"
                        type="text"
                        size="lg"
                        placeholder="000000"
                      />
                      <PasswordInput
                        id="password"
                        name="password"
                        size="lg"
                        placeholder="your new password"
                      />
                      <Button
                        className="w-100"
                        type="submit"
                        btnStyle="primary"
                        isLoading={isSubmitting}
                      >
                        Reset
                      </Button>
                      {!hasError && <ErrorHandling error={hasError} />}
                      <NavLink to="/login" className="nav-link text-center">
                        back to login
                      </NavLink>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default ResetPassword
