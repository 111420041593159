import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Container } from 'components/Container'
import Centered from 'components/Centered'
import CardOrNot from 'components/CardOrNot'
import { Button } from '@shimple-solutions/mm-ui-kit'
import ImageOne from 'components/ImageOne'
import { Heading } from 'components/Heading'
import { Text } from 'components/Text'

// Assets
import logo from '../assets/images/cat-confirm-phone.svg'

const RegisterSuccess = ({ history, location }) => {
  if (!location.state?.user) {
    return <Redirect to="/" />
  }
  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex flex-column align-items-center justify-content-center text-center p-3">
                <ImageOne src={logo} alt="cat paw confirm" />
              </div>
              <div className="col-sm text-center text-md-start p-3">
                <Heading>Your account has been verified.</Heading>
                <Text>
                  Thank you for signing up {location.state.user.username}! Your
                  account has been verified.
                  <br />
                  You may now proceed to login.
                </Text>
                <Button
                  type="button"
                  className="w-100"
                  btnStyle="primary"
                  onClick={() =>
                    history.push('/login', { user: location.state.user })
                  }
                >
                  Log In
                </Button>
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

RegisterSuccess.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}

export default RegisterSuccess
