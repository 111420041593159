import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
// TODO: (https://github.com/nfl/react-helmet/issues/548)
import { Helmet } from 'react-helmet'
import { SWRConfig } from 'swr'

import Routes from 'components/router/index'

function App() {
  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        dedupingInterval: 10000,
      }}
    >
      <Helmet defaultTitle="Mana API - %s" title="Mana API" />
      <Router>
        <Routes />
      </Router>
    </SWRConfig>
  )
}

export default App
