import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import Centered from 'components/Centered'
import CardOrNot from 'components/CardOrNot'
import { Container } from 'components/Container'
import { Button } from '@shimple-solutions/mm-ui-kit'
import { Input, PasswordInput } from 'components/Input'
import { Subheading } from 'components/Heading'
import ErrorHandling from 'components/ErrorHandling'
import ImageOne from 'components/ImageOne'
import { Text } from 'components/Text'

// Assets
import CatLogo from 'assets/images/sneakyCat.svg'
import api from 'common/api'

// eslint-disable-next-line no-unused-vars
const RegisterForm = ({ history, location, onSubmit }) => {
  const [hasError, setError] = useState(false)

  // Sends signup request and on success navigates to verification screen
  const signUpRequest = (formVal) => {
    return api
      .post('/signup', formVal)
      .then((resp) => {
        if (resp.data.error === false) {
          console.log('Response', resp)
          history.push('/login-verify', { user: formVal })
        }
        if (resp.data.error === true) {
          setError(resp.data.error.message)
        }
      })
      .then(onSubmit(formVal))
      .catch((error) => {
        console.log('Error response', error.message)
        setError(error)
      })
  }
  return (
    <Formik
      initialValues={{
        name: '',
        // email: location.state?.email || '',
        email: '',
        username: '',
        password: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string().max(20, 'Invalid Name').required('Name is required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required'),
        username: Yup.string()
          .min(4, 'Invalid Name')
          .required('Username is required'),
        password: Yup.string().required('Password is required'),
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
        //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        // ),
      })}
      onSubmit={(formValues) => signUpRequest(formValues)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Subheading>You are almost there!</Subheading>
          <Input
            id="name"
            name="name"
            type="text"
            size="lg"
            placeholder="name"
          />
          <Input
            id="email"
            name="email"
            type="text"
            size="lg"
            placeholder="email"
          />
          <Input
            id="username"
            name="username"
            type="text"
            size="lg"
            placeholder="username"
          />
          <PasswordInput
            id="password"
            name="password"
            size="lg"
            placeholder="password"
          />
          {hasError && <ErrorHandling error={hasError} />}
          <Button
            type="submit"
            btnStyle="primary"
            className="w-100"
            isLoading={isSubmitting}
          >
            Sign Up
          </Button>
          <Text className="text-center my-3">or</Text>
          <Link className="nav-link text-center" to="/login">
            login
          </Link>
        </Form>
      )}
    </Formik>
  )
}

const Register = ({ history, location }) => {
  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex align-items-center justify-content-center p-3">
                <ImageOne src={CatLogo} alt="cartoon cat smiling" />
              </div>
              <div className="col-sm d-flex align-items-center justify-content-center p-3">
                <RegisterForm history={history} location={location} />
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

Register.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

RegisterForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onSubmit: PropTypes.func,
}

export { Register, RegisterForm }
