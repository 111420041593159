import cx from 'classnames'
import PropTypes from 'prop-types'

const GenericCard = ({ className, children, ...props }) => (
  <div className={cx('card', 'bg-light', className)} {...props}>
    <div className="card-body">{children}</div>
  </div>
)

GenericCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default GenericCard
