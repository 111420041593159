import { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

// Components
import { Button } from '@shimple-solutions/mm-ui-kit'
import { Input, PasswordInput } from 'components/Input'
import { Container } from 'components/Container'
import Centered from 'components/Centered'
import CardOrNot from 'components/CardOrNot'
import ImageOne from 'components/ImageOne'
import { Subheading } from 'components/Heading'
import ErrorHandling from 'components/ErrorHandling'

// Assets
import logo from 'assets/images/anotherCat.png'
import api from 'common/api'
import { loginSuccess } from 'redux/actions'

// eslint-disable-next-line no-unused-vars
const LoginForm = ({ history, location, onSubmit }) => {
  const dispatch = useDispatch()
  const [hasError, setError] = useState()

  const loginRequest = (formVal) => {
    // onSubmit(formVal)
    return api
      .post('/signin', formVal)
      .then(({ data }) => {
        if (!data.error) {
          dispatch(loginSuccess(data))
          history.push('/')
        }
        if (data.error === true) {
          console.log('ResponseErr', data)
          setError(data.message)
        }
        if (data.data.user_verified === false) {
          history.push('/login-verify', { user: formVal })
        }
      })
      .then(onSubmit(formVal))
      .catch((error) => {
        console.log('Error response', error.message)
        setError(error)
      })
  }

  return (
    <Formik
      initialValues={{
        // username: location.state?.user?.username || '',
        username: '',
        password: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string()
          .min(4, 'Invalid username')
          .required('Username is required'),
        password: Yup.string().required('Password is required'),
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
        //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        // ),
      })}
      onSubmit={(formValues) => loginRequest(formValues)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Subheading className="text-center text-md-start">Login</Subheading>
          <Input
            id="username"
            name="username"
            size="lg"
            placeholder="username"
          />
          <PasswordInput
            id="password"
            name="password"
            size="lg"
            placeholder="password"
          />
          {hasError && <ErrorHandling error={hasError} />}
          <Button
            type="submit"
            btnStyle="primary"
            className="fw-bold w-100"
            isLoading={isSubmitting}
          >
            Login
          </Button>
        </Form>
      )}
    </Formik>
  )
}

const Login = ({ history, location }) => {
  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex align-items-center justify-content-center p-3">
                <ImageOne src={logo} alt="cartoon cat smiling" />
              </div>
              <div className="col-sm p-3">
                <LoginForm history={history} location={location} />
                <Link className="text-center nav-link" to="/forgot-password">
                  forgot password
                </Link>
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

LoginForm.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onSubmit: PropTypes.func,
}

export { Login, LoginForm }
