import cx from 'classnames'
import PropTypes from 'prop-types'
import GenericCard from './GenericCard'

import styles from './CardOrNot.module.scss'

const CardOrNot = ({ children, className, ...props }) => (
  <GenericCard className={cx(styles.container, className)} {...props}>
    {children}
  </GenericCard>
)

CardOrNot.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default CardOrNot
