import React from 'react'

import Card from '../components/Card'
import Centered from '../components/Centered'
import { Container } from '../components/Container'
// eslint-disable-next-line no-unused-vars
import { Text } from '../components/Text'
// eslint-disable-next-line no-unused-vars
import Table from '../components/Table'

// eslint-disable-next-line no-unused-vars
const cardInfo = [
  {
    tier: 'Free',
    cost: '0',
    features: ['Access to basic data', '60 requests / min'],
    is_coming_soon: false,
  },
  {
    tier: 'Basic',
    cost: '29',
    features: [
      'Access to basic data',
      'Access to geographic data',
      '600 requests / min',
    ],
    is_coming_soon: true,
  },
  {
    tier: 'Pro',
    cost: '99',
    features: [
      'Access to basic data',
      'Access to geographic data',
      '6000 requests / min',
    ],
    is_coming_soon: true,
  },
]

const Pricing = () => (
  <Container>
    <Centered>
      {cardInfo.map((data) => (
        <Card
          tier={data.tier}
          cost={data.cost}
          features={data.features}
          comingSoon={data.is_coming_soon}
        />
      ))}
    </Centered>
  </Container>
)

export default Pricing
