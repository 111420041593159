// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import BaseLayout from 'components/BaseLayout'
import { Button } from '@shimple-solutions/mm-ui-kit'
import { Container } from 'components/Container'
import { Heading, Subheading } from 'components/Heading'
import { Text } from 'components/Text'

const NotFound = () => (
  <Container>
    <BaseLayout className="d-flex flex-column justify-content-center text-center text-md-start">
      <Heading>404</Heading>
      <Subheading>
        Sorry, the page you were trying to view does not exist.
      </Subheading>
      <Text>
        If this link used to work, please take note that we are making
        significant changes across the site to improve your experience! Check
        back later.
        <br />
        Return to the home page
      </Text>
      <Link to="/">
        <Button>Back to homepage</Button>
      </Link>
    </BaseLayout>
  </Container>
)

export default NotFound
