// Library imports
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSWR, { mutate } from 'swr'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { Button } from '@shimple-solutions/mm-ui-kit'
// Components
import APIKeysTable from 'components/APIKeysTable'
import { Modal } from 'components/modal/Modal'
import Loading from 'components/Loading'
import Portal from 'components/Portal'
import { modalToggled } from 'components/modal/modalSlice'
import { Text } from 'components/Text'
import { Input } from 'components/Input'
import ErrorHandling from 'components/ErrorHandling'

// Resources
import api from 'common/api'

const APIKeysSection = () => {
  const dispatch = useDispatch()
  const [hasError, setError] = useState(null)
  const { modalVisibility } = useSelector((s) => s.modal)
  const { data, error } = useSWR('get-api-keys', (url) => api.get(url))

  if (modalVisibility) {
    // TODO: Click on backdrop to dismiss modal
    const backdropEl = document.getElementById('backdrop')

    if (backdropEl) {
      backdropEl.addEventListener('click', () => {
        dispatch(modalToggled(false))
      })
    }
  }

  if (!data) {
    // TODO: ERROR HANDLING
    console.log(error)
    return <Loading />
  }

  const createAPIKey = ({ appName }) => {
    console.log(appName)
    return (
      api
        .post('/create-api-key', { app_name: appName })
        .then((resp) => {
          if (resp.data.error === false) {
            // after api key created
            console.log('Response', resp)
            mutate('get-api-keys').then((r) => console.log(r))
            dispatch(modalToggled(false))
          }
          if (resp.data.error === true) {
            setError(resp.data.message)
          }
        })
        // eslint-disable-next-line no-shadow
        .catch((error) => {
          console.log('Error response', error.message)
          setError(error)
        })
    )
  }

  return (
    <div className="container-fluid d-flex flex-column">
      {modalVisibility && (
        <Portal>
          <Modal
            bodyClassName="d-flex flex-column align-items-center justify-content-center"
            title="Create API Key"
          >
            <Formik
              initialValues={{
                appName: '',
              }}
              validationSchema={Yup.object({
                appName: Yup.string()
                  .min(4, 'Name must be between 4 and 10 characters')
                  .max(10, 'Name must be between 4 and 10 characters')
                  .required('App name is required to proceed.'),
              })}
              onSubmit={(formVal) => createAPIKey(formVal)}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Text>
                    Input your app name, the API Key will be generated.
                  </Text>
                  <Input
                    label="App Name"
                    id="appName"
                    name="appName"
                    type="text"
                    size="lg"
                    placeholder="eg. GeoLocation Service"
                  />
                  {hasError && <ErrorHandling error={hasError} />}
                  <div className="modal-footer d-flex justify-content-center">
                    <Button
                      type="button"
                      btnStyle="secondary"
                      className="fw-bold"
                      onClick={() => dispatch(modalToggled(false))}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      btnStyle="primary"
                      className="fw-bold"
                      isLoading={isSubmitting}
                    >
                      + Create API
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
          <div className="modal-backdrop fade show" id="backdrop" />
        </Portal>
      )}
      <div className="align-self-end">
        <Button
          btnStyle="primary"
          className="fw-bold mb-3"
          onClick={() => dispatch(modalToggled(true))}
        >
          + Create API
        </Button>
      </div>
      <APIKeysTable apiArray={data.data.data.data} />
    </div>
  )
}

export default APIKeysSection
