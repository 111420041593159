import PropTypes from 'prop-types'

// TODO: make a general card
const Card = ({ tier, cost, features, comingSoon }) => (
  <div className="card w-25 m-auto">
    <div className="d-flex flex-column align-items-center justify-content-center border-bottom fw-bolder p-5">
      <h3>{tier}</h3>
      <p>${cost}/month</p>
    </div>
    <div className="d-flex flex-column card-body bg-primary align-items-center justify-content-center p-3">
      <p className="card-text text-white text-center">
        {features.map((text) => (
          <>
            {text} <br />
          </>
        ))}
        <br />
      </p>
      <p className="card-text text-white fw-bold">
        {comingSoon ? 'Coming Soon' : null}
      </p>
    </div>
  </div>
)

Card.propTypes = {
  tier: PropTypes.string,
  cost: PropTypes.string,
  features: PropTypes.array,
  comingSoon: PropTypes.bool,
}

export default Card
