import { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { NavLink, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import { Button } from '@shimple-solutions/mm-ui-kit'
import CardOrNot from 'components/CardOrNot'
import Centered from 'components/Centered'
import { Container } from 'components/Container'
import { Input } from 'components/Input'
import ImageOne from 'components/ImageOne'
import ErrorHandling from 'components/ErrorHandling'
import { Text } from 'components/Text'

// Assets
import CatLogo from 'assets/images/catFood.svg'
import api from 'common/api'
import { Subheading } from 'components/Heading'

const LoginVerification = ({ location, history }) => {
  const [hasError, setError] = useState(null)
  const verifyUser = (formVal) => {
    setError(null)
    return api
      .post('/confirm-signup', formVal)
      .then((resp) => {
        if (resp.data.error === false) {
          history.push('/register-success', { user: location.state?.user })
        }
        if (resp.data.error === true) {
          setError(resp.data.message)
        }
      })
      .catch((error) => {
        setError(error)
      })
  }

  if (!location.state?.user) {
    return <Redirect to="/" />
  }
  return (
    <Container>
      <Centered>
        <CardOrNot>
          <div className="container">
            <div className="row">
              <div className="col-sm d-flex align-items-center justify-content-center p-3">
                <ImageOne src={CatLogo} alt="Shaking hand" />
              </div>
              <div className="col-sm">
                <Formik
                  initialValues={{
                    verificationCode: '',
                  }}
                  validationSchema={Yup.object({
                    verificationCode: Yup.string()
                      .max(6, 'Code must have a length of 6')
                      .required('Code is required to continue.'),
                  })}
                  onSubmit={(formVal) => {
                    const val = {
                      username: location.state.user.username,
                      confirmation_code: formVal.verificationCode,
                    }
                    return verifyUser(val)
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Subheading>Confirmation</Subheading>
                      <Text>
                        Dear {location.state.user.username}, a 6 digit
                        verification code has been sent to your email{' '}
                        <b>{location.state.user.email}</b>. Please input the
                        code to confirm your registration.
                      </Text>
                      <Input
                        label="Verification Code"
                        id="verificationCode"
                        name="verificationCode"
                        type="text"
                        size="lg"
                        placeholder="000000"
                      />
                      {hasError && <ErrorHandling error={hasError} />}
                      <Button
                        type="submit"
                        btnStyle="primary"
                        className="w-100"
                        isLoading={isSubmitting}
                      >
                        Confirm
                      </Button>
                      <NavLink to="/login" className="nav-link text-center">
                        back to login
                      </NavLink>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </CardOrNot>
      </Centered>
    </Container>
  )
}

LoginVerification.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
}

export default LoginVerification
