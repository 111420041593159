import { useSelector } from 'react-redux'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Heading } from '../Heading'

import styles from './Modal.module.scss'

export const Modal = ({ title, children, bodyClassName }) => {
  const { modalVisibility } = useSelector((s) => s.modal)

  return (
    <div
      className={cx(
        'modal fade',
        modalVisibility && 'show',
        modalVisibility && styles.showModal,
      )}
      id="modal"
      tabIndex="-1"
      aria-labelledby="modal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <Heading className="modal-title">{title}</Heading>
          </div>
          <div className={cx('modal-body', bodyClassName)}>{children}</div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  bodyClassName: PropTypes.string,
}
