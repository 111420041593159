import PropTypes from 'prop-types'
// import cx from 'classnames'
import { CopyBlock, codepen } from 'react-code-blocks'

import { LeadingText, Text } from './Text'

// import styles from './APIRequestInfo.module.scss'

const replacer = (string, variableMap) => {
  return Object.keys(variableMap).reduce((a, b) => {
    return a.replace(b, variableMap[b])
  }, string)
}

const APIRequestInfo = ({ apiObj, ...props }) => {
  const {
    request: {
      url: { host, path },
    },
    response,
    variables,
  } = apiObj
  const url = [...host, ...path].join('/')
  const formattedUrl = replacer(url, variables)
  return (
    <div
      className="d-flex flex-column py-3 border-bottom border-secondary"
      {...props}
    >
      <div>
        <div className="d-flex align-items-center gap-2 my-2">
          <LeadingText className="text-success m-0">
            {apiObj.request.method}
          </LeadingText>
          <LeadingText className="m-0">{apiObj.name}</LeadingText>
        </div>
        <div>
          <CopyBlock
            language="javascript"
            text={formattedUrl}
            theme={codepen}
            showLineNumbers={false}
            codeBlock
          />
          {/* <CodeText className={cx('border border-dark p-1', styles.bgGray)}>
            {formattedUrl}
          </CodeText> */}
        </div>
      </div>
      <div>
        <p className="font-monospace border-bottom my-3">PARAMS</p>
        <div className="container d-flex flex-column">
          {apiObj.request.url.query !== undefined &&
            apiObj.request.url.query.map((item) => (
              <div className="row" key={item.key}>
                <div className="col-lg-3">
                  <Text className="fw-bold m-0">{`${item.key}`}</Text>
                  <Text className="fw-light">{`${item.description}`}</Text>
                </div>
                <div className="col-lg">
                  <Text>{`${item.value}`}</Text>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div>
        <p className="font-monospace border-bottom my-3">RESPONSE</p>
        <div className="container">
          {response.map((each) => (
            <>
              <div className="row" key={each.id}>
                <div className="col">
                  <Text className="fw-bold mt-3">{each.name}</Text>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <CopyBlock
                    language="javascript"
                    text={each.body}
                    theme={codepen}
                    showLineNumbers
                    codeBlock
                  />
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

APIRequestInfo.propTypes = {
  apiObj: PropTypes.object,
}

export default APIRequestInfo
