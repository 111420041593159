import PropTypes from 'prop-types'
import { useField } from 'formik'
import cx from 'classnames'

import {
  Input as CustomInput,
  PasswordInput as CustomPasswordInput,
} from '@shimple-solutions/mm-ui-kit'

export const Input = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <CustomInput
        meta={meta}
        label={label}
        name={field.name}
        className={cx(className)}
        {...field}
        {...props}
      />
      {meta.error && meta.touched && (
        <p className="text-danger">{meta.error}</p>
      )}
    </>
  )
}

export const PasswordInput = ({ label, className, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <CustomPasswordInput
        meta={meta}
        name={field.name}
        label={label}
        className={cx(className)}
        {...field}
        {...props}
      />
      {meta.error && meta.touched && (
        <p className="text-danger">{meta.error}</p>
      )}
    </>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

PasswordInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}
