import { ContainerWithSidebar } from 'components/Container'

const UserProfileContent = () => <p>hello</p>

// Initial Commit
const UserProfile = () => {
  return (
    <ContainerWithSidebar>
      <UserProfileContent />
    </ContainerWithSidebar>
  )
}

export default UserProfile
