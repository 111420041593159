import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './Centered.module.scss'

const BaseLayout = ({ className, children }) => (
  <div className={cx('container', styles.container, className)}>{children}</div>
)

BaseLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default BaseLayout
