import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './Loading.module.scss'

const Loading = ({ className, isFullScreen, isComponent, ...props }) => (
  <div
    className={cx(
      isFullScreen
        ? 'd-flex vw-100 vh-100 align-items-center justify-content-center'
        : undefined,
      isComponent ? styles.loadingComponent : undefined,
      className,
    )}
    {...props}
  >
    <div className="spinner-grow text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

Loading.propTypes = {
  className: PropTypes.string,
  isFullScreen: PropTypes.bool,
  isComponent: PropTypes.bool,
}

export default Loading
