import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import cx from 'classnames'

// Redux
import { submitLogout } from 'redux/actions'

// Components
import { Button } from '@shimple-solutions/mm-ui-kit'

// Resources
import styles from './Sidebar.module.scss'

const Content = ({ children }) => (
  // TODO: Styling
  <div className="container-fluid d-flex justify-content-center align-items-center">
    {children}
  </div>
)

const SidebarLinks = () => {
  return (
    <div className="border-secondary border-start">
      <Link className={cx('nav-link', styles.blackText)} to="/">
        API Keys
      </Link>
      <Link className={cx('nav-link', styles.blackText)} to="/user-profile">
        Profile
      </Link>
      <Link className={cx('nav-link', styles.blackText)} to="/documentation">
        Documentation
      </Link>
    </div>
  )
}

const Sidebar = ({ children }) => {
  const dispatch = useDispatch()
  return (
    // TODO : Styling
    <nav className="d-flex">
      <div
        className={cx(
          'nav flex-column p-3 gap-2 border-end border-primary justify-content-center',
          styles.sidebarContainer,
        )}
        aria-orientation="vertical"
      >
        <h5 className="fw-bold pb-2">Manamakan Developer</h5>

        <SidebarLinks />

        <Button
          btnStyle="secondary"
          className="fw-bold m-3"
          onClick={() => dispatch(submitLogout())}
        >
          Logout
        </Button>
      </div>
      <Content>{children}</Content>
    </nav>
  )
}

Content.propTypes = {
  children: PropTypes.node,
}

Sidebar.propTypes = {
  children: PropTypes.node,
}

export default Sidebar
