import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Routes
import { Home } from 'containers/Home'
import { Register } from 'containers/Register'
import RegisterSuccess from 'containers/RegisterSuccess'
import Pricing from 'containers/Pricing'
import { Login } from 'containers/Login'
import LoginVerification from 'containers/LoginVerification'
import ForgotPasswordRequest from 'containers/ForgotPasswordRequest'
import ResetPasswordSuccess from 'containers/ResetPasswordSuccess'
import ResetPassword from 'containers/ResetPassword'
import Documentation from 'containers/Documentation'
import NotFound from 'containers/NotFound'

// Private Routes
import PrivateHome from 'containers/user/PrivateHome'
import UserProfile from 'containers/user/UserProfile'

// Components & Resources
import Loading from 'components/Loading'
import { checkHasUser } from 'redux/actions'

const routes = [
  { path: '/', exact: true, Component: Home },
  { path: '/register', Component: Register },
  { path: '/register-success', Component: RegisterSuccess },
  { path: '/pricing', Component: Pricing },
  { path: '/login', Component: Login },
  { path: '/login-verify', Component: LoginVerification },
  { path: '/forgot-password', Component: ForgotPasswordRequest },
  { path: '/reset-password', Component: ResetPassword },
  {
    path: '/reset-password-success',
    Component: ResetPasswordSuccess,
  },
  {
    path: '/documentation',
    Component: Documentation,
  },
  { path: '*', Component: NotFound },
]

const privateRoutes = [
  { path: '/', exact: true, Component: PrivateHome },
  { path: '/user-profile', exact: true, Component: UserProfile },
  {
    path: '/documentation',
    exact: true,
    Component: Documentation,
  },
  { path: '*', Component: NotFound },
]

const Router = () => {
  const { is_logged_in } = useSelector((s) => s.auth)
  const [isLoading, setIsLoading] = useState(true)
  const selectedRoutes = is_logged_in ? privateRoutes : routes
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkHasUser()).finally(() => setIsLoading(false))
  }, [])

  if (isLoading) {
    return <Loading isFullScreen />
  }
  return (
    <Switch>
      {selectedRoutes.map(({ path, exact, Component }) => (
        <Route key={path} path={path} exact={exact} component={Component} />
      ))}
    </Switch>
  )
}

export default Router
