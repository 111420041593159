import { LOGIN_SUCCESS, SIGN_OUT } from './constants'

const initialState = {
  is_logged_in: false,
  access_token: null,
  user: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_OUT:
      return initialState
    case LOGIN_SUCCESS:
      return {
        is_logged_in: true,
        access_token: action.payload,
      }
    default:
      return state
  }
}

export default authReducer
