class DocModel {
  constructor(doc) {
    this.variableMap = this.generateVariableMap(doc.collection.variable)
    this.collection = doc.collection.item.map((each) => ({
      ...each,
      variables: this.variableMap,
    }))
  }

  generateVariableMap(variables) {
    return variables.reduce((a, b) => {
      const c = Object.assign(a, {})
      c[`{{${b.key}}}`] = b.value
      return c
    }, {})
  }
}

export default DocModel
