import cx from 'classnames'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { ErrorText } from './Text'

const ErrorHandling = ({ className, error }) => {
  if (!error) return null
  if (typeof error === 'object') {
    const checkError = get(error, 'response.data')
    if (checkError) {
      if (checkError.data) {
        return (
          <ErrorText>
            {checkError.message}
            <br />
            {Object.keys(checkError.data).map((each, index) => (
              <span key={index}>
                {checkError.data[each]}
                <br />
              </span>
            ))}
          </ErrorText>
        )
      }
      return <ErrorText>{checkError.message}</ErrorText>
    }
    return 'error'
  }
  return <ErrorText className={cx('text-danger', className)}>{error}</ErrorText>
}

ErrorHandling.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  className: PropTypes.string,
}

export default ErrorHandling
